<template>
	<b-container fluid>
		<b-row class="content-header">
			<!-- Content Left -->
			<b-col class="content-header-left px-0" cols="12" md="12">
				<b-row class="breadcrumbs-top">
					<b-col class="px-0" cols="12">
						<div class="breadcrumb-wrapper h-100">
							<b-breadcrumb>
								<template v-if="$route.path !== '/project-list'">
									<b-breadcrumb-item class="h-100" to="/project-list">
										<feather-icon :class="isDark ? 'text-light' : 'text-dark'" class="align-text-top" icon="MapIcon" size="16" />
									</b-breadcrumb-item>
									<b-breadcrumb-item
										v-if="$store.state.menus.current.params.project_name"
										:to="{
											name: 'index-project-detail',
											params: {
												project_id: $store.state.menus.current.params.project_id,
												title: '项目主页',
												project_name: $store.state.menus.current.params.project_name,
											},
										}"
									>
										<span :class="isDark ? 'text-light' : 'text-dark'" class="mb-0" style="padding-left: 0.2rem; margin-bottom: 0.2rem">
											{{ $store.state.menus.current.params.project_name }}
										</span>
									</b-breadcrumb-item>
									<b-breadcrumb-item v-for="item in $store.state.menus.current.params.levels" :key="item.title" :to="{ name: item.name }" class="h-100">
										<span :class="isDark ? 'text-light' : 'text-dark'">{{ $t(item.title) }} </span>
									</b-breadcrumb-item>
									<b-breadcrumb-item :to="$store.state.menus.current">
										<span :class="isDark ? 'text-light' : 'text-dark'">{{ $t($store.state.menus.current.params.title) }}</span>
									</b-breadcrumb-item>
								</template>
							</b-breadcrumb>
						</div>
					</b-col>
				</b-row>
			</b-col>

			<!-- Content Right -->
			<!--		<b-col class="content-header-right text-md-right mb-1" cols="12" md="3">-->
			<!--			<b-dropdown-->
			<!--					class="d-none"-->
			<!--					no-caret-->
			<!--					right-->
			<!--					toggle-class="p-0"-->
			<!--					variant="link"-->
			<!--			>-->
			<!--				<template #button-content>-->
			<!--					<b-button-->
			<!--							v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
			<!--							class="btn-icon"-->
			<!--							variant="primary"-->
			<!--					>-->
			<!--						<feather-icon icon="SettingsIcon"/>-->
			<!--					</b-button>-->
			<!--				</template>-->
			<!--				-->
			<!--				<b-dropdown-item :to="{ name: 'apps-todo' }">-->
			<!--					<feather-icon icon="CheckSquareIcon" size="16"/>-->
			<!--					<span class="align-middle ml-50">Todo</span>-->
			<!--				</b-dropdown-item>-->
			<!--				-->
			<!--				<b-dropdown-item :to="{ name: 'apps-chat' }">-->
			<!--					<feather-icon icon="MessageSquareIcon" size="16"/>-->
			<!--					<span class="align-middle ml-50">Chat</span>-->
			<!--				</b-dropdown-item>-->
			<!--				-->
			<!--				<b-dropdown-item :to="{ name: 'apps-email' }">-->
			<!--					<feather-icon icon="MailIcon" size="16"/>-->
			<!--					<span class="align-middle ml-50">Email</span>-->
			<!--				</b-dropdown-item>-->
			<!--				-->
			<!--				<b-dropdown-item :to="{ name: 'apps-calendar' }">-->
			<!--					<feather-icon icon="CalendarIcon" size="16"/>-->
			<!--					<span class="align-middle ml-50">Calendar</span>-->
			<!--				</b-dropdown-item>-->
			<!--			</b-dropdown>-->
			<!--		</b-col>-->
		</b-row>
	</b-container>
</template>

<script>
	import Ripple from "vue-ripple-directive";

	export default {
		directives: {
			Ripple,
		},
		props: ["isDark"],
	};
</script>
