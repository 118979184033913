var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left px-0",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"breadcrumb-wrapper h-100"},[_c('b-breadcrumb',[(_vm.$route.path !== '/project-list')?[_c('b-breadcrumb-item',{staticClass:"h-100",attrs:{"to":"/project-list"}},[_c('feather-icon',{staticClass:"align-text-top",class:_vm.isDark ? 'text-light' : 'text-dark',attrs:{"icon":"MapIcon","size":"16"}})],1),(_vm.$store.state.menus.current.params.project_name)?_c('b-breadcrumb-item',{attrs:{"to":{
										name: 'index-project-detail',
										params: {
											project_id: _vm.$store.state.menus.current.params.project_id,
											title: '项目主页',
											project_name: _vm.$store.state.menus.current.params.project_name,
										},
									}}},[_c('span',{staticClass:"mb-0",class:_vm.isDark ? 'text-light' : 'text-dark',staticStyle:{"padding-left":"0.2rem","margin-bottom":"0.2rem"}},[_vm._v(" "+_vm._s(_vm.$store.state.menus.current.params.project_name)+" ")])]):_vm._e(),_vm._l((_vm.$store.state.menus.current.params.levels),function(item){return _c('b-breadcrumb-item',{key:item.title,staticClass:"h-100",attrs:{"to":{ name: item.name }}},[_c('span',{class:_vm.isDark ? 'text-light' : 'text-dark'},[_vm._v(_vm._s(_vm.$t(item.title))+" ")])])}),_c('b-breadcrumb-item',{attrs:{"to":_vm.$store.state.menus.current}},[_c('span',{class:_vm.isDark ? 'text-light' : 'text-dark'},[_vm._v(_vm._s(_vm.$t(_vm.$store.state.menus.current.params.title)))])])]:_vm._e()],2)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }