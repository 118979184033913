<template>
	<b-nav-item-dropdown class="dropdown-user" right toggle-class="d-flex align-items-center dropdown-user-link">
		<template #button-content>
			<div class="d-sm-flex d-none user-nav">
				<p class="user-name font-weight-bolder mb-0">
					{{ userData.user_alias || userData.username }}
				</p>
				<span class="user-status">{{ $t(userRole) }}</span>
			</div>
			<b-avatar :src="userData.avatar" badge badge-variant="success" class="badge-minimal border-success" size="40" variant="light-primary">
				<feather-icon v-if="!userData.user_name" icon="UserIcon" size="22" />
			</b-avatar>
		</template>

		<b-dropdown-item :to="{ name: 'admin-account-setting', params: { title: 'Account Settings' } }" link-class="d-flex align-items-center">
			<feather-icon class="mr-50" icon="SettingsIcon" size="16" />
			<span>{{ $t("Account Settings") }}</span>
		</b-dropdown-item>

		<b-dropdown-item :to="{ name: 'admin-projects-list', params: { title: 'Projects Manage', icon: 'GridIcon', levels: [{ title: 'System Manage' }] } }" link-class="d-flex align-items-center">
			<feather-icon class="mr-50" icon="GridIcon" size="16" />
			<span>{{ $t("Projects Manage") }}</span>
		</b-dropdown-item>

		<b-dropdown-item :to="{ name: 'admin-users-list', params: { title: 'Users Manage', icon: 'UserIcon', levels: [{ title: 'System Manage' }] } }" link-class="d-flex align-items-center">
			<feather-icon class="mr-50" icon="UserIcon" size="16" />
			<span>{{ $t("Users Manage") }}</span>
		</b-dropdown-item>

		<b-dropdown-item link-class="d-flex align-items-center" @click="logout">
			<feather-icon class="mr-50" icon="LogOutIcon" size="16" />
			<span>{{ $t("Logout") }}</span>
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
	import { BAvatar, BDropdownDivider, BDropdownItem, BNavItemDropdown } from "bootstrap-vue";
	import { avatarText } from "@core/utils/filter";
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { logout } from "@/libs/utils/logout";
	import { routerParams } from "@/libs/utils/routerParams";

	export default {
		components: {
			BNavItemDropdown,
			BDropdownItem,
			BDropdownDivider,
			BAvatar,
		},
		setup() {
			let event = reactive({
				userData: computed(() => {
					let userData = {};
					if (localStorage.getItem("userData") !== undefined || localStorage.getItem("userData") != null) {
						userData = JSON.parse(localStorage.getItem("userData"));
					}
					return userData;
				}),
				project_id: computed(() => {
					return routerParams("project_id");
				}),
				userRole: "",
				avatarText,
			});
			watch(
				() => event.project_id,
				() => {
					getUserRole();
				}
			);
			const getUserRole = () => {
				let role = "";
				event.userData.projects.map((project) => {
					if (parseInt(project.project_id) === parseInt(event.project_id)) {
						role = project.role.name;
					}
				});
				event.userRole = role;
			};
			onMounted(() => {
				getUserRole();
			});
			return {
				...toRefs(event),
				logout,
			};
		},
		watch: {
			"store.state.projects.current": (value) => {
				console.log(value);
			},
		},
	};
</script>
